import React from 'react';
// import { Link } from 'react-router-dom';

function EventTab() {
  return (
    <div>
{/*Events One Start*/}
<section className="events-one">
            <div className="events-one-shape-1" style={{backgroundImage: 'url(assets/images/shapes/events-one-shape-1.png) ' }}>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-xl-4 col-lg-4">
                        <div className="events-one__left">
                            <div className="section-title text-left">
                                <span className="section-title__tagline"> A Healthier Tomorrow </span>
                                <h2 className="section-title__title">Our Medical Outreach  </h2>
                            </div>
                            <p className="events-one__text-1">
                            Join us on a journey of compassion and healing as we bring healthcare to underserved areas.
                            </p>
                            <a href="events" className="thm-btn events-one__btn">Discover More</a>
                        </div>
                    </div>
                    <div className="col-xl-8 col-lg-8">
                        <div className="events-one__right">
                          
    <div className="embed-responsive embed-responsive-16by9" >
    <iframe 
    title="NTA News with Tynachyomaa" 
    className="embed-responsive-item" src="https://www.youtube.com/embed/hUmmdorBPLQ" 
    style={{ width: '100%', height: '450px' }} 
    allowfullscreen 
    ></iframe>
    </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/*Events One End*/}

        </div>
  );
}
export default EventTab;

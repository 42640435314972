import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
 

function GalleryFull() {
    const [imageCountPort, setImageCountPort] = useState(0);
 

    useEffect(() => {
      const fetchImageCount = async () => {
        try {
          const response = await fetch('https://secure.tynachyomaa.com/gallery-port' );
          const data = await response.json();
          setImageCountPort(data);
          console.log(data);
        } catch (error) {
          console.error('Failed to fetch image count:', error.message);
        }
      };
  
      fetchImageCount();
    }, []);
  
    // ... rest of your component
  // }
  
  
  
      const renderItems = () => {
      const items = [];
      for (let i = 0; i < imageCountPort; i++) {
          
          //setImageCount({i});
        items.push(
          // <div className="item" key={i}>
          //   <div className="gallery-page__single">
          //     <div className="gallery-page__img">
          //       {/* <h1>Count: {`${imageCount + i}`}</h1> */}
          //       <img
          //         src={`assets/images/gallery/port/${imageCount + i}.jpg`}
          //         alt={`${imageCount + i}`}
          //       />
          //       <a href={`assets/images/gallery/port/${imageCount + i}.jpg`} className="img-popup"> </a>
          //       <div className="gallery-page__content">
          //         <p className="gallery-page__sub-title">TynaChyomaa</p>
          //         <h5 className="gallery-page__title">
          //           <a href="gallery">Food Support</a>
          //         </h5>
          //       </div>
          //     </div>
          //   </div>
          // </div>
  
  
              
                      <div className="item"  key={i} >
                          <div className="news-one__single">
                              <div className="news-one__img">
                              {/* <h1> {i} </h1> */}
                              <img
                                  src={`assets/images/gallery/port/${i}.jpeg`}
                                  alt={`${i}`}
                                 // width="200" 
                                  height="500" 
                              />
                              </div>
                             
                          </div>
                      </div>
                    
                      
  
        );
      }
      return items;
    };
  
   
  
    return (
      <div> 
  
  { /*Gallery Page Start*/ }
          <section className="gallery-carousel-page">
              <div className="container">
                  <div className="gallery-carousel thm-owl__carousel owl-theme owl-carousel carousel-dot-style" data-owl-options='{
                      "items": 3,
                      "margin": 30,
                      "smartSpeed": 700,
                      "loop":true,
                      "autoplay": 6000,
                      "nav":false,
                      "dots":true,
                      "navText": ["<span className=\"fa fa-angle-left\"></span>","<span className=\"fa fa-angle-right\"></span>"],
                      "responsive":{
                          "0":{
                              "items":1
                          },
                          "768":{
                              "items":2
                          },
                          "992":{
                              "items": 3
                          }
                      }
                  }'>
  
  
  
                  { /*Gallery Page Single Start 
                  <div className="item">
                      <div className="gallery-page__single">
                          <div className="gallery-page__img">
                              <h1> Count: {`${imageCount}`} </h1>
                              <img  src={`assets/images/gallery/port/${imageCount}.jpg`}  alt={`${imageCount}`} />
                              <a href={`assets/images/gallery/port/${imageCount}.jpg`} className="img-popup"> </a>
                              
                              <div className="gallery-page__content">
                                  <p className="gallery-page__sub-title">TynaChyomaa</p>
                                  <h5 className="gallery-page__title"><a href="gallery">Food Support</a></h5>
                              </div>
                          </div>
                      </div>
                  </div>
                  { /*Gallery Page Single End*/ }
  
  
  {renderItems()}
  

  </div>
            </div>
        </section>
     
        </div>
  );
}
export default GalleryFull;

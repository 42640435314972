// import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import HeaderTab from '../comp/HeaderTab';
import FooterTab from '../comp/FooterTab';
import BreadcrumGallery from '../comp/BreadcrumGallery';
import GalleryLands from '../comp/GalleryLands';
import GalleryPort from '../comp/GalleryPort'; 



function Gallery() {
  return (
    <div>
    <Helmet>
        <title>Gallery | Witness Our Impact</title>
        <meta name="keywords" content="gallery, images, photos, TynaChyomaa, charitable foundation, gallery, orphans, youth empowerment, vocational education, widows " />
        <meta name="description" content="Explore our photo gallery to see the positive impact of your support on the lives of orphans, youth, and widows. Witness firsthand the transformation and hope we bring to our communities." />
      </Helmet>
         <HeaderTab />
         <BreadcrumGallery />
         {/* <GalleryTab /> */}
         <GalleryPort /> 
         <GalleryLands />
         <FooterTab />
    </div>
  );
}
export default Gallery;
